


















import firebase from "firebase/app";
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class ListView extends Vue {
  authLoading = false;

  async googleLogin() {
    this.authLoading = true;
    await firebase
      .auth()
      .signInWithPopup(new firebase.auth.GoogleAuthProvider());
    this.$emit("close");
    this.authLoading = false;
  }
}
