import { formatId } from "@/lib";
import { ItemsList } from "@/models/models";
import store from "@/store";
import Vue from "vue";

export default class ListService extends Vue {
  static async getList(id: string): Promise<ItemsList> {
    const res = await Vue.prototype.$db
      .collection("lists")
      .doc(id)
      .get();
    if (!res.exists) {
      return Promise.reject(new Error("listNotFound"));
    }
    return res.data();
  }

  static async createListFromList(
    list: ItemsList
  ): Promise<{ id: string; list: ItemsList }> {
    let id = formatId(list.title);
    if (id) {
      const res = await Vue.prototype.$db
        .collection("lists")
        .doc(id)
        .get();
      if (res.exists) id = "";
    }
    const user: any = store.state.user;
    if (user && !list.roles) {
      list.roles = { [user.uid]: "owner" };
    }
    list.updatedAt = new Date();
    if (!list.createdAt) list.createdAt = new Date();
    const res = await Vue.prototype.$db
      .collection("lists")
      .doc(id || undefined);
    res.set(list);
    return { id: res.id, list };
  }

  static async deleteList(id: string): Promise<any> {
    try {
      await this.getList(id);
      await Vue.prototype.$db
        .collection("lists")
        .doc(id)
        .delete();
      return Promise.resolve();
    } catch (error) {
      return Promise.reject();
    }
  }

  static async updateList(id: string, list: ItemsList) {
    list.updatedAt = new Date();
    if (!list.createdAt) list.createdAt = new Date();
    const user: any = store.state.user;
    if (user && !list.roles) {
      list.roles = { [user.uid]: "owner" };
    }
    return await Vue.prototype.$db
      .collection("lists")
      .doc(id)
      .set(list);
  }
}

// this.$db.collection("users").add({
//   first: "Marc",
//   last: "Perez",
//   born: 1815
// });
