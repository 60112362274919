




























import { Component, Mixins } from "vue-property-decorator";
import VInputMixin from "./VInputMixin";

@Component({
  inheritAttrs: false
})
export default class VInputPassword extends Mixins(VInputMixin) {
  viewPassword = false;

  set inputValue(val: string | number | undefined) {
    this.$emit("change", val);
  }

  get inputValue() {
    return this.value;
  }

  get listeners() {
    return Object.assign({}, this.$listeners, {
      // This ensures that the component works with v-model
      change: (event: any) => {
        this.$emit("change", event.target.value);
      }
    });
  }

  togglePasswordView() {
    this.viewPassword = !this.viewPassword;
    (this.$refs.input as any).focus();
  }
}
