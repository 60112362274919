














import { Component, Mixins } from "vue-property-decorator";
import VInputMixin from "./VInputMixin";

@Component({
  inheritAttrs: false
})
export default class VTextArea extends Mixins(VInputMixin) {
  set inputValue(val: string | undefined) {
    this.$emit("change", val);
  }

  get inputValue() {
    return this.value;
  }
}
