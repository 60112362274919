import store from "@/store";
import { mutationsT } from "@/store/mutations";
import firebase from "firebase/app";
import Vue from "vue";

export default class UserService extends Vue {
  static async getUserLists(): Promise<Array<any>> {
    const user: any = store.state.user;
    if (!user) return [];
    const res = await Vue.prototype.$db
      .collection("lists")
      .where(`roles.${user.uid}`, "==", "owner")
      .get();
    const lists: any = [];
    if (res.docs) {
      res.forEach((doc: any) => {
        lists.push({ docId: doc.id, ...doc.data() });
      });
    }
    return lists;
  }

  static async postDeleteFavoriteHandler(
    id: string,
    method: "post" | "delete"
  ): Promise<Array<any>> {
    const user: any = store.state.user;
    if (!user) return [];
    const queryFavorites = await Vue.prototype.$db
      .collection("favorites")
      .doc(user.uid);

    const favoritesList = await queryFavorites.get();
    let favoritesIds: any = favoritesList.data();
    if (favoritesList.exists) {
      if (method === "delete") {
        queryFavorites.update(
          "listIds",
          firebase.firestore.FieldValue.arrayRemove(id)
        );
      } else {
        queryFavorites.update(
          "listIds",
          firebase.firestore.FieldValue.arrayUnion(id)
        );
      }
    } else {
      favoritesIds = { listIds: [id] };
      queryFavorites.set(favoritesIds);
    }

    this.getFavorites();
    return favoritesList;
  }

  static async postFavorite(id: string): Promise<Array<any>> {
    return await this.postDeleteFavoriteHandler(id, "post");
  }

  static async deleteFavorite(id: string): Promise<Array<any>> {
    return await this.postDeleteFavoriteHandler(id, "delete");
  }

  static async getFavorites(): Promise<Array<any>> {
    const user: any = store.state.user;
    if (!user) return [];
    const favoritesQuery = await Vue.prototype.$db
      .collection("favorites")
      .doc(user.uid);

    const favoritesIds = await favoritesQuery.get();
    const res = await Vue.prototype.$db
      .collection("lists")
      .where(
        firebase.firestore.FieldPath.documentId(),
        "in",
        favoritesIds.data().listIds
      )
      .get();

    const favoritesList: any = [];
    if (res.docs) {
      res.forEach((doc: any) => {
        favoritesList.push({ docId: doc.id, ...doc.data() });
      });
    }
    store.commit(mutationsT.UPDATE_USER_FAVORITES, favoritesList);
    return favoritesList;
  }
}
