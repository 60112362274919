

















































import { Component, Vue } from "vue-property-decorator";

import SignInModal from "@/components/SignInModal.vue";

@Component({
  metaInfo: {
    title: "Create your wish list - AnyWish",
    meta: [
      {
        name: "description",
        content:
          "Wishlists, wedding lists, ideas lists, task lists. Create any list you want and share it instantly"
      },
      {
        property: "og:description",
        content:
          "Wishlists, wedding lists, ideas lists, task lists. Create any list you want and share it instantly"
      }
    ]
  },
  components: { SignInModal }
})
export default class ListView extends Vue {
  langs = ["es", "en"];
}
