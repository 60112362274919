import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";
import Vue from "vue";
import store from "./store";
import { mutationsT } from "./store/mutations";

const firebaseConfig = {
  apiKey: "AIzaSyCB7F7GtSPbDHQ5GMuaplew12rcGuUg3lY",
  authDomain: "anywishlist-98d00.firebaseapp.com",
  databaseURL: "https://anywishlist-98d00.firebaseio.com",
  projectId: "anywishlist-98d00",
  storageBucket: "anywishlist-98d00.appspot.com",
  messagingSenderId: "319916737173",
  appId: "1:319916737173:web:7e39b4a667f64e7c80d19c",
  measurementId: "G-RFHLJV7JKK"
};

try {
  firebase.initializeApp(firebaseConfig);
  // .addScope(
    //   "https://www.googleapis.com/auth/contacts.readonly"
    // );
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    firebase.auth().onAuthStateChanged(function (user) {
      store.commit(mutationsT.UPDATE_USER, user);
    });
    
  Vue.prototype.$analytics = firebase.analytics();
  Vue.prototype.$db = firebase.firestore();
  Vue.prototype.$db.enablePersistence();
} catch (err) {
  if (err.code == "failed-precondition") {
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
    // ...
  } else if (err.code == "unimplemented") {
    // The current browser does not support all of the
    // features required to enable persistence
    // ...
  }
}

//   db.collection("cities").where("state", "==", "CA")
//   .onSnapshot({ includeMetadataChanges: true }, function(snapshot) {
//       snapshot.docChanges().forEach(function(change) {
//           if (change.type === "added") {
//               console.log("New city: ", change.doc.data());
//           }

//           var source = snapshot.metadata.fromCache ? "local cache" : "server";
//           console.log("Data came from " + source);
//       });
//   });
