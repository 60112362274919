








import { Component, Vue } from "vue-property-decorator";
import CreateList from "@/components/CreateList.vue";

@Component({
  components: {
    CreateList
  }
})
export default class Home extends Vue {}
