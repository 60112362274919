

























































import { Component, Mixins, Watch } from "vue-property-decorator";
import VInputMixin from "./VInputMixin";
import ClickOutside from "vue-click-outside";

export interface VSelectOptions {
  text: string;
  value: string;
}

@Component({
  inheritAttrs: false,
  directives: {
    ClickOutside
  }
})
export default class VDatePicker extends Mixins(VInputMixin) {
  showCalendar = false;
  today: Date = new Date();
  viewingYear: number = new Date().getFullYear();
  viewingMonth: number = new Date().getMonth();
  selectedDate: Date | null = null;

  monthsString: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  daysString: string[] = ["M", "T", "W", "T", "F", "S", "S"];

  @Watch("selectedDate")
  onSelectedDateChanged(val: Date | undefined) {
    if (val) this.$emit("change", val.toISOString());
  }

  created() {
    this.selectedDate = new Date(this.value) || this.today;
  }

  inputClicked() {
    if (this.$attrs.disabled) return;
    this.showCalendar = !this.showCalendar;
  }

  get listeners() {
    return Object.assign({}, this.$listeners, {
      // This ensures that the component works with v-model
      change: (event: any) => {
        this.$emit("change", event.target.value);
      }
    });
  }

  isDateSelected(day: number) {
    if (!this.selectedDate) return false;
    if (this.selectedDate.getDate() !== day) {
      return false;
    }
    if (this.selectedDate.getMonth() !== this.viewingMonth) {
      return false;
    }
    if (this.selectedDate.getFullYear() !== this.viewingYear) {
      return false;
    }
    return true;
  }

  get monthData() {
    const firstDay = new Date(this.viewingYear, this.viewingMonth, 1);
    const lastDay = new Date(this.viewingYear, this.viewingMonth + 1, 0);
    return {
      firstDay,
      lastDay,
      offset: firstDay.getDay(),
      days: Array.from(Array(lastDay.getDate()).keys()).map(
        (d: number) => d + 1
      )
    };
  }

  selectDate(day: number) {
    this.selectedDate = new Date(this.viewingYear, this.viewingMonth, day);
  }

  hideCalendar() {
    this.showCalendar = false;
  }

  increaseMonth() {
    if (this.viewingMonth < 11) {
      this.viewingMonth++;
    } else {
      this.viewingMonth = 0;
      this.viewingYear++;
    }
  }

  decreaseMonth() {
    if (this.viewingMonth > 0) {
      this.viewingMonth--;
    } else {
      this.viewingMonth = 11;
      this.viewingYear--;
    }
  }
}
