export type Wish = {
  title: string;
  description: string;
  price: string;
  url: string;
  imageUrl: string;
};

export type ItemsList = {
  id: string;
  title: string;
  description: string;
  items: Array<Wish>;
  roles: any;
  updatedAt: any;
  createdAt: any;
};

export function newEmptyWish(): Wish {
  return { title: "", description: "", price: "", url: "", imageUrl: "" };
}

export function newEmptyItemsList(id?: string): ItemsList {
  return {
    id: id || "",
    title: "",
    description: "",
    items: [],
    roles: null,
    updatedAt: null,
    createdAt: null
  };
}

export function isEmptyWish(wish: Wish): boolean {
  return !wish || (!wish.title && !wish.description && !wish.url);
}
