















































import { Component, Prop, Mixins } from "vue-property-decorator";
import VInputMixin from "./VInputMixin";
import ClickOutside from "vue-click-outside";

export interface VSelectOptions {
  text: string;
  value: string;
}

@Component({
  inheritAttrs: false,
  directives: {
    ClickOutside
  }
})
export default class VTextField extends Mixins(VInputMixin) {
  @Prop()
  options!: VSelectOptions[];
  showOptions = false;

  set inputValue(val: string | number | undefined) {
    this.$emit("change", val);
  }

  get inputValue() {
    return this.value;
  }

  hideOptions() {
    this.showOptions = false;
  }

  toggleOptions() {
    this.showOptions = !this.showOptions;
  }
}
