








// HOW TO USE:
// this.$root.$emit('v-flash-notice', 'There has been an error')
// this.$root.$emit('v-flash-notice', {error: 'There has been an error'})
// this.$root.$emit('v-flash-notice', {text: 'There has been an error', type: 'error'})

import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class VFlashNotice extends Vue {
  text = "";
  type = "";

  created() {
    this.$root.$on("v-flash-notice", (t: any) => {
      if (t && t.error) {
        this.text = t.error;
        this.type = "error";
      } else if (t && t.type) {
        this.text = t.text;
        this.type = t.type;
      } else {
        this.text = t;
      }

      setTimeout(() => {
        this.text = "";
      }, 3000);
    });
  }
}
