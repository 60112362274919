

























import { Component, Mixins } from "vue-property-decorator";
import VInputMixin from "./VInputMixin";

@Component({
  inheritAttrs: false
})
export default class VTextEditable extends Mixins(VInputMixin) {
  txt = this.value;

  onInput(e: any) {
    this.$emit("change", e.target.innerText);
  }

  mounted() {
    (this.$refs.contenteditable as any).innerText = this.value;
  }

  get listeners() {
    return Object.assign({}, this.$listeners, {
      input: this.onInput
    });
  }
}
