



















import { Component, Vue } from "vue-property-decorator";

import ListService from "@/api/ListService";
import { newEmptyItemsList } from "@/models/models";
@Component({})
export default class ListView extends Vue {
  error = "";
  list = newEmptyItemsList();

  async createList() {
    if (this.list.title) {
      try {
        const res = await ListService.createListFromList(this.list);
        this.$router.push(`/${res.id}`);
      } catch (error) {
        console.dir(error)
        this.error = error.message;
      }
    }
  }
}
