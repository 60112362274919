




















































































































































































































import { Component, Vue } from "vue-property-decorator";
import VueClipboards from "vue-clipboards";
import SignInModal from "@/components/SignInModal.vue";
import axios from "axios";
import { validURL, clipTitle, validAffiliateURL } from "@/lib";
import UserService from "@/api/UserService";

Vue.use(VueClipboards);

import {
  isEmptyWish,
  ItemsList,
  newEmptyItemsList,
  newEmptyWish,
  Wish
} from "@/models/models";
import ListService from "@/api/ListService";
@Component({ components: { SignInModal } })
export default class ListView extends Vue {
  listId?: string;
  list: ItemsList = newEmptyItemsList();
  newItem: Wish = newEmptyWish();
  showCompleteForm = false;
  quickInput = "";
  link = window.location.href;
  copied = false;
  editActive = false;
  loadingCrawlUrl = false;
  error = null;
  loadingList = true;
  showDeleteModal = false;

  copiedSuccess() {
    this.copied = true;
    setTimeout(() => {
      this.copied = false;
    }, 3000);
  }

  get isFavorite() {
    return this.$store.state.userFavorites.filter(
      (f: any) => f.docId === this.listId || f.id === this.listId
    ).length;
  }

  get editable() {
    const user = this.$store.state.user;
    if (this.list.roles && !user) return false;
    if (!this.list.roles) return true;
    return (
      this.list.roles &&
      (this.list.roles[user.uid] === "owner" ||
        this.list.roles[user.uid] === "write")
    );
  }

  get unlocked() {
    return this.editActive && this.editable;
  }

  renderLink(url: string){
    return validAffiliateURL(url);
  }

  async quickAddItem() {
    if (!this.quickInput) return;
    this.loadingCrawlUrl = true;
    if (validURL(this.quickInput)) {
      try {
        const res = await axios.post(
          //"http://localhost:5001/anywishlist-98d00/europe-west1/scrapeUrl",
          "https://europe-west1-anywishlist-98d00.cloudfunctions.net/scrapeUrl",
          {
            url: validURL(this.quickInput)
          }
        );
        this.newItem.title =
          clipTitle(res.data["og:title"]) ||
          clipTitle(res.data.title) ||
          this.quickInput;
        this.newItem.description =
          res.data["og:description"] || res.data.description || "";
        const url = res.data.canonical || this.quickInput || "";
        this.newItem.url = url;
        this.newItem.imageUrl = res.data["og:image"] || res.data.image || "";
        const price =
          res.data["og:price:amount"] + res.data["og:price:currency"];
        this.newItem.price =
          price || res.data["og:price"] || res.data.price || "";
      } catch (error) {
        this.loadingCrawlUrl = false;
      }
    } else {
      this.newItem.title = this.quickInput;
    }

    this.addItem();
    this.quickInput = "";
    this.loadingCrawlUrl = false;
  }

  async addItem() {
    if (isEmptyWish(this.newItem)) return;
    if (this.list.items) this.list.items.push(this.newItem);
    else this.list.items = [this.newItem];
    await this.updateList();
    this.newItem = newEmptyWish();
  }

  updateListTitle() {
    if (this.list.title) this.updateList();
  }

  deleteItem(index: number) {
    this.list.items.splice(index, 1);
    this.updateList();
  }

  async updateList() {
    if (this.listId) await ListService.updateList(this.listId, this.list);
  }

  async deleteList() {
    if (this.listId) {
      await ListService.deleteList(this.listId);
      this.$router.push("/");
    }
  }

  clickFavorite() {
    this.isFavorite ? this.deleteFavorite() : this.postFavorite();
  }

  async postFavorite() {
    if (this.listId) await UserService.postFavorite(this.listId);
  }

  async deleteFavorite() {
    if (this.listId) await UserService.deleteFavorite(this.listId);
  }

  async created() {
    this.listId = this.$route.params.listId;
    this.loadingList = true;
    if (this.listId) {
      try {
        this.list = await ListService.getList(this.listId);
      } catch (error) {
        this.error = error;
        this.loadingList = false;
      }
    }
    this.loadingList = false;
  }
}
