export function validURL(str: string) {
  try {
    const url = new URL(str);
    return url.origin + url.pathname;
  } catch (error) {
    return "";
  }
}

export function validAffiliateURL(str: string) {
  try {
    const url = new URL(str);
    let query = "";
    if (str.indexOf("amazon") > -1) query = "?tag=anywish0e-21";
    return url.origin + url.pathname + query;
  } catch (error) {
    return "";
  }
}

export function clipTitle(str: string) {
  if (!str) return str;
  let newStr = str;
  if (str.indexOf(" - ") > 0) newStr = str.substring(0, str.indexOf(" - ") + 1);
  if (newStr.length > 50) newStr = newStr.slice(0, 50);
  return newStr;
}

export function formatId(id: string | undefined): string {
  if (!id) return "";
  return id.replace(/[^a-zA-Z0-9]/g, "-").toLowerCase();
}
