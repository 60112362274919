import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

import { mutations } from "./mutations";
import actions from "./actions";

Vue.use(Vuex);
const vuexPersist = new VuexPersist({
  key: "anywish",
  storage: window.sessionStorage
});

export type State = any;

export default new Vuex.Store({
  plugins: [vuexPersist.plugin],
  state: {
    user: null,
    userLists: [],
    userFavorites: []
  },
  mutations,
  actions,
  modules: {}
});
