











































































































import { Component, Vue, Prop, Watch, Provide } from "vue-property-decorator";
import { isPromise } from "./helpers";

export interface VStepInfo {
  header: string;
  name: string;
}

@Component({})
export default class VSteps extends Vue {
  // @Prop({ type: Boolean })
  loading = false;

  @Prop({ type: Boolean })
  disabled?: boolean;

  @Prop({ type: Boolean, default: true })
  showNumbers?: boolean;

  @Prop({ type: Boolean, default: false })
  showOnlyNumbers?: boolean;

  @Prop({ type: Boolean, default: true })
  showNav?: boolean;

  @Prop({ type: Number, default: 0 })
  showNavAfterStep?: number;

  @Prop({ type: Number, default: 0 })
  nonCountableSteps!: number;

  @Prop({ type: Boolean, default: true })
  showPrevButton?: boolean;

  @Prop({ type: Boolean, default: false })
  showNextButton?: boolean;

  @Prop({ default: "#2963ff" })
  color?: string;

  @Prop({ default: "" })
  tabStyle?: string;

  @Prop({ default: "Next" })
  nextButtonText?: string | Array<string>;

  @Prop({ default: "" })
  transition?: string;

  @Prop({ default: "Back" })
  backButtonText?: string;

  @Prop({ default: "Finish" })
  finishButtonText?: string;

  @Prop({ default: 0 })
  startIndexProp?: number;

  activeTabIndex = 0;
  currentPercentage = 0;
  maxStep = 0;
  tabs: Array<any> = [];
  validateOnBack = false;

  startIndex: number = this.startIndexProp || 0;

  get tabCount() {
    return this.tabs.length;
  }

  get tabCountPercentage() {
    return this.tabs.length - this.nonCountableSteps;
  }

  get isLastStep() {
    return this.activeTabIndex === this.tabCount - 1;
  }
  get displayPrevButton() {
    return this.showPrevButton && this.activeTabIndex !== 0;
  }
  get stepPercentage() {
    return (1 / (this.tabCountPercentage * 2)) * 100;
  }
  get progressBarStyle() {
    return {
      backgroundColor: this.color,
      width: `${this.progress}%`,
      color: this.color
    };
  }
  get fillButtonStyle() {
    return {
      backgroundColor: this.color,
      borderColor: this.color,
      color: "white"
    };
  }

  get progress() {
    let percentage = 0;
    if (this.activeTabIndex > 0) {
      const stepsToAdd = 1;
      const stepMultiplier = 2;
      percentage =
        this.stepPercentage *
        (this.activeTabIndex * stepMultiplier + stepsToAdd);
    } else {
      percentage = this.stepPercentage;
    }
    return percentage;
  }

  initializeTabs() {
    if (this.tabs.length > 0 && this.startIndex === 0) {
      this.activateTab(this.activeTabIndex);
    }
    if (this.startIndex < this.tabs.length) {
      this.activateTabAndCheckStep(this.startIndex);
    } else {
      // window.console.warn(
      //   `Prop startIndex set to ${this.startIndex} is greater than the number of tabs -
      //   ${this.tabs.length}. Make sure that the starting index
      //   is less than the number of tabs registered`
      // );
    }
  }

  emitTabChange(prevIndex: number, nextIndex: number) {
    this.$emit("on-change", prevIndex, nextIndex);
    this.$emit("update:startIndex", nextIndex);
  }
  @Provide()
  addTab(item: any) {
    const index =
      (this.$slots.default && this.$slots.default.indexOf(item.$vnode)) || 3;
    item.tabId = `${item.title.replace(/ /g, "")}${index}`;
    this.tabs.splice(index, 0, item);
    // if a step is added before the current one, go to it
    if (index < this.activeTabIndex + 1) {
      this.maxStep = index;
      this.changeTab(this.activeTabIndex + 1, index);
    }
  }

  @Provide()
  removeTab(item: any) {
    const tabs = this.tabs;
    const index = tabs.indexOf(item);
    if (index > -1) {
      // Go one step back if the current step is removed
      if (index === this.activeTabIndex) {
        this.maxStep = this.activeTabIndex - 1;
        this.changeTab(this.activeTabIndex, this.activeTabIndex - 1);
      }
      if (index < this.activeTabIndex) {
        this.maxStep = this.activeTabIndex - 1;
        this.activeTabIndex = this.activeTabIndex - 1;
        this.emitTabChange(this.activeTabIndex + 1, this.activeTabIndex);
      }
      tabs.splice(index, 1);
    }
  }

  changeTab(oldIndex: number, newIndex: number, emitChangeEvent = true) {
    const oldTab = this.tabs[oldIndex];
    const newTab = this.tabs[newIndex];
    if (oldTab) {
      oldTab.active = false;
    }
    if (newTab) {
      newTab.active = true;
    }
    if (emitChangeEvent && this.activeTabIndex !== newIndex) {
      this.emitTabChange(oldIndex, newIndex);
    }
    this.activeTabIndex = newIndex;
    this.activateTabAndCheckStep(this.activeTabIndex);
    return true;
  }

  deactivateTabs() {
    this.tabs.forEach(tab => {
      tab.active = false;
    });
  }

  activateTab(index: number) {
    this.deactivateTabs();
    const tab = this.tabs[index];
    if (tab) {
      tab.active = true;
      tab.checked = true;
      this.tryChangeRoute(tab);
    }
  }

  activateTabAndCheckStep(index: number) {
    this.activateTab(index);
    if (index > this.maxStep) {
      this.maxStep = index;
    }
    this.activeTabIndex = index;
  }

  tryChangeRoute(tab: any) {
    if (this.$router.currentRoute.path === "/verify") {
      this.$router.replace(tab.route).catch(() => {
        return;
      });
    } else {
      if (
        this.$router &&
        tab.route &&
        tab.route !== this.$router.currentRoute.path
      ) {
        this.$router.push(tab.route).catch(() => {
          return;
        });
      }
    }
  }

  @Watch("$route")
  onRouteChange(to: any, from: any) {
    if (to.path === from.path && to.path !== "/verify") return;
    for (let i = 0; i < this.tabs.length; i++) {
      if (this.tabs[i].route === to.path) {
        this.navigateToTab(i);
      }
    }
  }

  navigateToTab(index: number) {
    const validate = index > this.activeTabIndex;
    if (index <= this.maxStep) {
      const cb = () => {
        if (validate && index - this.activeTabIndex > 1) {
          // validate all steps recursively until destination index
          this.changeTab(this.activeTabIndex, this.activeTabIndex + 1);
          this.beforeTabChange(this.activeTabIndex, cb);
        } else {
          this.changeTab(this.activeTabIndex, index);
          this.afterTabChange(this.activeTabIndex);
        }
      };
      if (validate) {
        this.beforeTabChange(this.activeTabIndex, cb);
      } else {
        this.setValidationError(null);
        cb();
      }
    }
    return index <= this.maxStep;
  }

  navigateToLastTab() {
    this.changeTab(this.activeTabIndex, this.tabCount - 1);
    this.afterTabChange(this.activeTabIndex);
    this.$emit("on-complete");
  }

  nextTab() {
    const cb = () => {
      if (this.activeTabIndex < this.tabCount - 1) {
        this.changeTab(this.activeTabIndex, this.activeTabIndex + 1);
        this.afterTabChange(this.activeTabIndex);
      } else {
        this.$emit("on-complete");
      }
    };
    this.beforeTabChange(this.activeTabIndex, cb);
  }

  prevTab() {
    const cb = () => {
      if (this.activeTabIndex > 0) {
        this.setValidationError(null);
        this.changeTab(this.activeTabIndex, this.activeTabIndex - 1);
      }
    };
    if (this.validateOnBack) {
      this.beforeTabChange(this.activeTabIndex, cb);
    } else {
      cb();
    }
  }

  // focusNextTab() {
  //   let tabIndex = getFocusedTabIndex(this.tabs as any);
  //   if (tabIndex !== -1 && tabIndex < this.tabs.length - 1) {
  //     let tabToFocus = this.tabs[tabIndex + 1];
  //     if (tabToFocus.checked) {
  //       findElementAndFocus(tabToFocus.tabId);
  //     }
  //   }
  // }
  // focusPrevTab() {
  //   let tabIndex = getFocusedTabIndex(this.tabs as any);
  //   if (tabIndex !== -1 && tabIndex > 0) {
  //     let toFocusId = this.tabs[tabIndex - 1].tabId;
  //     findElementAndFocus(toFocusId);
  //   }
  // }
  setLoading(value: boolean) {
    this.loading = value;
    this.$emit("on-loading", value);
  }
  setValidationError(error: any) {
    this.tabs[this.activeTabIndex].validationError = error;
    this.$emit("on-error", error);
  }
  validateBeforeChange(promiseFn: any, callback: any) {
    this.setValidationError(null);
    if (isPromise(promiseFn)) {
      this.setLoading(true);
      promiseFn
        .then((res: any) => {
          this.setLoading(false);
          const validationResult = res === true;
          this.executeBeforeChange(validationResult, callback);
        })
        .catch((error: any) => {
          this.setLoading(false);
          this.setValidationError(error);
        });
    } else {
      const validationResult = promiseFn === true;
      this.executeBeforeChange(validationResult, callback);
    }
  }
  executeBeforeChange(validationResult: any, callback: any) {
    this.$emit("on-validate", validationResult, this.activeTabIndex);
    if (validationResult) {
      callback();
    } else {
      this.tabs[this.activeTabIndex].validationError = "error";
    }
  }
  beforeTabChange(index: number, callback: any) {
    if (this.loading) {
      return;
    }
    const oldTab = this.tabs[index];
    if (oldTab && oldTab.beforeChange !== undefined) {
      const tabChangeRes = oldTab.beforeChange();
      this.validateBeforeChange(tabChangeRes, callback);
    } else {
      callback();
    }
  }
  afterTabChange(index: number) {
    if (this.loading) {
      return;
    }
    const newTab = this.tabs[index];
    if (newTab && newTab.afterChange !== undefined) {
      newTab.afterChange();
    }
  }

  mounted() {
    this.initializeTabs();
  }
}
