












import { Component, Vue, Prop, Inject } from "vue-property-decorator";

@Component({
  inject: ["addTab", "removeTab"]
})
export default class VStep extends Vue {
  @Inject() addTab!: Function;
  @Inject() removeTab!: Function;
  @Prop()
  title?: string;
  /***
   * Icon name for the upper circle corresponding to the tab
   */
  @Prop()
  icon?: string;
  @Prop()
  beforeChange!: Function;
  /***
   * Function to execute after tab switch. Return void for now.
   * Safe to assume necessary validation has already occured
   */
  @Prop()
  afterChange!: Function;

  @Prop()
  route?: string | Record<string, any>;

  @Prop()
  additionalInfo?: Record<string, any>;

  active = false;
  validationError: string | null = null;
  checked = false;
  tabId = "";

  mounted() {
    this.addTab(this);
  }

  destroyed() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    this.removeTab(this);
  }
}
