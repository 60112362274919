import UserService from "@/api/UserService";
import { State } from "./index";

export const mutationsT = {
  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_LISTS: "UPDATE_USER_LISTS",
  UPDATE_USER_FAVORITES: "UPDATE_USER_FAVORITES"
};

export const mutations = {
  UPDATE_USER(state: State, payload: any) {
    state.user = payload;
    UserService.getFavorites();
  },
  UPDATE_USER_LISTS(state: State, payload: any) {
    state.userLists = payload;
  },
  UPDATE_USER_FAVORITES(state: State, payload: any) {
    state.userFavorites = payload;
  }
};
