

















import { Component, Mixins } from "vue-property-decorator";
import VInputMixin from "./VInputMixin";

export interface VSelectOptions {
  text: string;
  value: string;
}

@Component({
  inheritAttrs: false
})
export default class VCheckbox extends Mixins(VInputMixin) {
  set inputValue(val: boolean | undefined) {
    this.$emit("change", val);
  }

  get inputValue() {
    return this.value;
  }
}
