

















import { Component, Mixins } from "vue-property-decorator";
import VInputMixin from "./VInputMixin";

@Component({ inheritAttrs: false })
export default class VInput extends Mixins(VInputMixin) {}
