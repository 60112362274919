



















































import { Component, Vue } from "vue-property-decorator";
import CreateList from "@/components/CreateList.vue";
import { mapActions } from "vuex";
import firebase from "firebase/app";

@Component({
  components: {
    CreateList
  },
  methods: {
    ...mapActions({
      fetchUserLists: "FETCH_USER_LISTS",
      fetchUserFavorites: "FETCH_USER_FAVORITES"
    })
  }
})
export default class Profile extends Vue {
  fetchUserLists!: () => any;
  fetchUserFavorites!: () => any;

  async signout() {
    await firebase.auth().signOut();
    this.$router.push("/");
  }

  mounted() {
    this.fetchUserLists();
    this.fetchUserFavorites();
  }
}
