import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./firebase.ts";
import "./components/ui-kit";
import VueMeta from "vue-meta";
import "./styles/fa-scss/fontawesome.scss";
import "./styles/fa-scss/duotone.scss";
import "./styles/fa-scss/solid.scss";
import "./styles/fa-scss/light.scss";
import i18n from "./i18n";
import VTooltip from "v-tooltip";
Vue.use(VTooltip);

Vue.use(VueMeta);

// max height on mobile without navbar
const vh = window.innerHeight * 0.01;
if (document && document.documentElement) {
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}
window.addEventListener("resize", () => {
  // We execute the same script as before
  const vh = window.innerHeight * 0.01;
  if (document && document.documentElement) {
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }
});

Vue.config.productionTip = false;

export const vue = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  data: {
    $showSignInChanged: false
  }
}).$mount("#app");
