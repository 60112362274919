import UserService from "@/api/UserService";
import { mutationsT } from "./mutations";

export const actionsT = {
  FETCH_USER_LISTS: "FETCH_USER_LISTS",
  FETCH_USER_FAVORITES: "FETCH_USER_FAVORITES"
};

export default {
  // async FETCH_USER({ commit, state }: any) {
  //   if (state.user) return Promise.resolve(state.user);
  // }
  // async FETCH_USER({ commit, state }: any) {
  //   if (state.user) return Promise.resolve(state.user);
  //   const user = await UserService.getUser();
  //   commit(mutationsT.UPDATE_USER, user.data);
  // },
  async FETCH_USER_LISTS({ commit, state }: any) {
    if (!state.user) return Promise.resolve(state.user);
    const lists = await UserService.getUserLists();
    commit(mutationsT.UPDATE_USER_LISTS, lists);
  },
  async FETCH_USER_FAVORITES({ commit, state }: any) {
    if (!state.user) return Promise.resolve(state.user);
    const lists = await UserService.getFavorites();
    commit(mutationsT.UPDATE_USER_FAVORITES, lists);
  }
};
